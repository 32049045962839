import React from "react";
import './ContactUs.css';

const ContactUs = () => {
    return (
        <section className="contactUs">
            <h1>Kontakt</h1>
            <div>
                <h3>Sie Möchten Renovieren oder neu Bauen?</h3>
                <p>Wir sind für Sie da: telefonisch, per E-Mail oder persönlich. Natürlich kommen wir auch gerne zur Projektbesprechung zu Ihnen nach Hause oder Geschäfts-Objekt.</p>
                <p>
                    <h3>AMM BAU</h3>
                    <h4>Westkotterstr. 102</h4>
                    <h4>42277 Wuppertal</h4>    
                </p>

                <p>
                    <h3>Öffnungszeiten: Mo-Sa: 08:00 - 19:00 Uhr</h3>
                    <h3>Telefon: +49 176 31568708</h3>
                    <h3>E-Mail: info@amm-bau.de</h3>
                </p>
            </div>
        </section>
    );
};

export default ContactUs;