import React from "react";
import './Sanitaetundheizung.css';

const Sanitaetundheizung = () => {
    return(
        <section className="sanitaetundheizung">
            <h1 className="titel">Sanitaet und Heizung</h1>
            <div>
                
                <div className="heizkoerper">   
                    <div>
                        <h2>Den Perfekten Heizkörper Finden: Tipps für Ihre Individuellen Bedürfnisse</h2>
                        <p>Planen Sie einen Neubau oder möchten Ihre alten Heizkörper austauschen? </p>
                        <p>
                            AMM Bau ist Ihr zuverlässiger Partner – von der Auswahl des passenden Heizkörpers bis hin zur kompletten Installation! 
                            Bei der Entscheidung für einen Heizkörper ist es wichtig, die Unterschiede in der Wärmeverteilung zu kennen. 
                            Grundsätzlich gibt es zwei Arten von Wärmeabgabe: Konvektionswärme und Strahlungswärme.
                        </p>
                        <p>
                            <b>Konvektionswärme</b> funktioniert durch das Erwärmen der Raumluft. 
                            Der Vorteil dieser Methode: Der Raum wird schnell warm, ohne lange Vorlaufzeiten. Allerdings hat dies den Nachteil, 
                            dass die erwärmte Luft zirkuliert und dabei Staub aufwirbeln kann – ein Problem, das vor allem Hausstauballergiker spüren könnten.
                        </p>
                        <p>
                            <b>Strahlungswärme</b> hingegen wirkt ähnlich wie Sonnenstrahlen: Statt die Luft zu erwärmen, werden die Wände und Möbel direkt aufgeheizt, 
                            was zu einer gleichmäßigeren Verteilung der Wärme führt. Der Nachteil hierbei ist, dass es länger dauert, bis der Raum die gewünschte Temperatur erreicht.
                        </p>
                        <p>
                            Mit AMM Bau finden Sie den perfekten Heizkörper, der genau zu Ihren Ansprüchen passt, und schaffen ein angenehmes Wohnklima in Ihrem Zuhause!
                        </p>
                    </div>
                    <img src= { require("../assets/images/heizkoerper-image.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
                </div>
            </div>
            <div>
                <div className="heiztechnik">
                    <div> 
                        <h2>Wir bringen modernste Heiztechnologie in Ihr Zuhause!</h2>
                        <p>
                            <b>Mit modernster Heizungstechnik garantieren wir Ihnen Zuverlässigkeit und Effizienz – egal bei welchem Energieträger oder Anwendungsbereich.</b>
                        </p>
                        <p>
                            <b>Heizung:</b> Wir bieten Ihnen die neueste Heiztechnik! Unabhängig vom Objekt, unsere erfahrenen Techniker und Facharbeiter sorgen für die 
                            Installation einer technisch einwandfreien Anlage, die genau auf Ihre individuellen Bedürfnisse abgestimmt ist.
                        </p>
                        <p>
                            Das Ergebnis: Ein umweltschonendes Heizsystem, das höchsten Komfort bietet, benutzerfreundlich ist und durch ansprechende Optik überzeugt. 
                            Darüber hinaus übernehmen wir auch die Einregulierung, Inbetriebnahme und Leistungsmessung Ihrer Heizungsanlage und stellen Ihnen eine umfassende 
                            Betriebsdokumentation zur Verfügung.
                        </p>
                    </div>
                    <img src= { require("../assets/images/heiztechniken.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
                </div>
            </div>
            
            <div>
                <div>
                    <h2>Wann und warum eine Heizungsmodernisierung sinnvoll ist</h2>
                    <p>
                        Es lässt sich nicht pauschal sagen, wann eine Heizung modernisiert werden sollte, da dies von der Art der Heizung, 
                        dem Wartungsintervall und dem verwendeten Brennstoff abhängt. Auch die Energieeffizienz Ihres Gebäudes spielt eine entscheidende Rolle. 
                        Daher ist es sinnvoll, spätestens nach 15 Jahren eine Energieberatung durchzuführen. Diese Beratung gibt Ihnen einen Überblick über mögliche 
                        Einsparpotenziale durch eine Heizungsmodernisierung. Optimalerweise erfolgt die Modernisierung im Frühjahr oder Sommer, wenn ein eventueller 
                        Heizungsausfall weniger spürbar ist.
                    </p>

                    <p>
                        Wir beraten Sie bei der Modernisierung immer auf Basis der Energieberatung und empfehlen die für Sie beste Heizungsart – sei es eine klassische 
                        Öl- oder Gasheizung, eine Hybridlösung, eine Wärmepumpe oder eine Biomasse-Heizung. Denn Ihre Heizanlage sollte nicht nur zu Ihren Bedürfnissen, 
                        sondern auch zu den Anforderungen Ihres Gebäudes passen.
                    </p>
                    
                </div>

            </div>

            <div>
                
                <div className="badzimmer">
                    <div>  
                        <h2>Ihr Traumbad wird Realität – mit uns an Ihrer Seite!</h2>
                        <p>
                            <b>Wie stellen Sie sich Ihr neues Bad vor?</b> Ob luxuriöse Wellness-Oase, praktisches Familienbad, cleveres Raumwunder 
                            oder barrierefreies Bad – wir setzen Ihre individuellen Wünsche und Vorstellungen gemeinsam mit Ihnen um. 
                        </p>
                        
                        <p>
                            Profitieren Sie von unserer umfassenden Beratung und durchdachten Planung. Lassen Sie sich inspirieren und holen Sie Ideen aus unserer 
                            ELEMENTS-Ausstellung. Auf unsere hochwertige und termingerechte Ausführung können Sie sich verlassen. Mit unserer langjährigen Erfahrung 
                            und Kompetenz in der Badsanierung können Sie entspannt zurücklehnen.
                        </p>

                        <p>
                            Dank klarer Prozesse können Sie Ihr neues Wohlfühlbad so schnell wie möglich genießen. 
                            Vor Staub und Schmutz während der Sanierung müssen Sie keine Angst haben. Wir setzen auf den Einsatz von Absauggeräten und Staubschutztüren, 
                            um eine staubarme Badmodernisierung sicherzustellen.
                        </p>
                    </div>
                    <img src= { require("../assets/images/badzimmer.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
                </div>
            </div>
            <div>
                <div className="badzimmer-planung">
                    <div>
                    <h2>Komplettplanung für Ihr Badezimmer</h2>
                    <p>
                        Ob ebenerdige Dusche, Mineralguss-Badewanne, Regenbrause oder stimmungsvolle Lichttechnik – Sie müssen nicht über alle aktuellen 
                        Badezimmer-Trends Bescheid wissen. Wir präsentieren Ihnen die neuesten Konzepte persönlich und im Detail.
                    </p>
                    
                    <p>
                        Bereits in der Planungsphase berücksichtigen wir die baulichen und technischen Gegebenheiten bei Ihnen vor Ort. 
                        Selbstverständlich bieten wir Ihnen auch die fachgerechte Umsetzung durch unsere professionellen Handwerker.
                    </p>
                    
                    <p> 
                        Kontaktieren Sie uns gerne, und wir planen gemeinsam Ihr neues Badezimmer als Wohlfühloase!
                    </p>
                    </div>
                    <img src= { require("../assets/images/badzimmer-plannung.png")} alt="Ein Raum, bevore und nach Renovierung" width={'350px'} height={'100%'}></img>
                </div>
            </div>
        </section>
    );
};

export default Sanitaetundheizung;