import React from "react";
import './Zaunanlage.css';

const Zaunanlage = () => {
    return (
        <section className="zaunanlage">
            <h1 className="titel">Zaunanlagen</h1>
            <div className="intor-zaunanlagen">
                <h2>Zaunanlagen für jeden Stil und jeden Bedarf</h2>
                <p>
                    Mit einer Zaunanlage setzen Sie eine klare Grenze und steigern gleichzeitig den Wert Ihres Grundstücks. 
                    Das Gartenhaus Montageteam Norddeutschland bietet die Montage von Zäunen aus Holz und Metall, passend zu Ihrem individuellen Geschmack 
                    und für zahlreiche Einsatzmöglichkeiten. Wir montieren Zäune aller Hersteller, darunter traditionelle Holzzäune wie den Jägerzaun 
                    oder Flechtzäune als Sichtschutz.
                </p>
                <p>
                    Auch für robuste Metallzäune sind wir Ihr Ansprechpartner: Ob Gittermaschenzäune, farbige Stahlzäune oder der klassische Maschendrahtzaun – wir 
                    sorgen für eine fachgerechte Montage Ihrer Zaunanlage, ganz nach Ihren Wünschen.
                </p>
            </div>
            
            <div className="beratung-zaunanlagen">
                <div>
                    <img src= { require("../assets/images/zaunanlagen-image1.png")} alt="Ein Raum, bevore und nach Renovierung" width={'400px'} height={'100%'}></img>
                    <h2>Beratung – Ein wichtiger Schritt bei Zaunanlagen</h2>
                    <p>Nutzen Sie unser Angebot einer Vor-Ort-Beratung, denn es gibt zahlreiche Aspekte, die abgestimmt werden müssen:</p>
                    <ul>
                        <li>Wo sollte der Zaun idealerweise verlaufen?</li>
                        <li>Wie und wo wird der Zaun am besten am Haus angebunden?</li>
                        <li>Wo sollen die Pforten positioniert werden?</li>
                        <li>Wo sind die Eckpfosten optimal zu setzen?</li>
                        <li>Wo beginnen wir mit der Zaunmontage?</li>
                        <li>Welche Zaunhöhe ist passend, abhängig von seiner Funktion, z. B. bei der Einfriedung eines Grundstücks?</li>
                        <li>Wie integrieren wir Einfahrten optimal in die Planung?</li>
                        <li>Welche Segmentaufteilung des Zauns ergibt sich daraus?</li>
                    </ul>
                    <p>
                        Alles wird im Kontext der Nutzung Ihres Gartens betrachtet. Mit unserer Erfahrung können wir Ihnen zudem den passenden Hersteller 
                        für Ihre spezifischen Bedürfnisse empfehlen. Vertrauen Sie auf unsere fachliche Beratung, damit Ihre Zaunanlage Ihnen optimalen 
                        Schutz und zusätzliche Sicherheit bietet!
                    </p>
                </div>
                
            </div>
            <div className="schluss-zaunanlagen">
                <div>
                    <h2>Zaunanlagen – Komplettservice aus einer Hand</h2>
                    <p>
                        Wir kümmern uns um alles: Vom Setzen der Fundamente für die Zaunpfosten über die professionelle Montage bis hin zur exakten Ausrichtung von Türen und Toren, 
                        damit diese reibungslos schließen. 
                    </p>
                    <p>
                        Auch die Entsorgung Ihrer alten Zäune übernehmen wir selbstverständlich für Sie. Je nach Länge des Zauns beträgt die Bauzeit in der Regel nur 1–2 Tage.
                    </p>
                 </div>
                 <img src= { require("../assets/images/zaunanlagen-image2.png")} alt="Ein Raum, bevore und nach Renovierung" width={'250px'} height={'100%'}></img>
            </div>
        </section>
    );
};

export default Zaunanlage;