import React, { useState} from "react";
import './Header.css';
import { Link } from "react-router-dom";


const Header = () => {

    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleItemClick = () => {
        setIsNavOpen(false);
    }

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };


    return (
        <header>
            <div className="hamburger-container">
                <button className="hamburger" onClick={toggleNav}>
                    {/*☰*/}
                    &#9776;
                </button>
                {/* hier the new method until the company-name*/}
                {isNavOpen && (
                    <div className="overlay">
                        <div className="burger-close-button" onClick={handleItemClick}>
                            &times;
                        </div>
                        <div className="burger-company-name">AMM BAU</div>
                        <nav className="burger-nav-menu">
                            <ul>
                                <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/">Home</Link></a></li>
                                <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/aboutUs">Über uns</Link></a></li>
                                <li onClick={toggleSubmenu}>
                                    Leistungen {isSubmenuOpen ? '▲' : '▼'}
                                    {isSubmenuOpen && (
                                    <ul className="burger-submenu">
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/fliesenarbeiten">Fliesenarbeiten</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/fensterundtueren">Fenster und Türen</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/renovierung">Renovierung</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/sanitaetundheizung">Sanität und Heizung</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/tapezierenundstreichen">Tapezieren und Streichen</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/trockenbau">Trockenbau</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/wasserschaeden">Wasserschäden</Link></a></li>
                                        <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/zaunanlage">Zaunanlage</Link></a></li>
                                    </ul>
                                    )}
                                </li>
                                <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/projects">Galerie</Link></a></li>
                                <li onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/contactUs">Kontakt</Link></a></li>
                            </ul>
                        </nav>
                    </div>
                )}


                <div className="company-name">AMM BAU</div>
            </div>
            <div className="navbar-contact">
                <ul>
                    <li>E-Mail: info@amm-bau.de</li>
                    <li>Tel: +49 176 31568708</li>
                </ul>
            </div>
            <nav className={`nav ${isNavOpen ? 'nav-open' : ''}`}> {/* className={`nav ${isNavOpen ? 'nav-open' : ''}`} , old = class="navbar" */}
                <ul className="nav-list">
                    <li className="nav-item" onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/">Home</Link></a></li>
                    <li className="nav-item" onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/aboutUs">Über uns</Link></a></li>
                    <li className="nav-item">
                        <div class="dropdown">
                            <a class="dropbtn" id="leistung-a" style={{color:'white'}}  href="#">Leistungen {/*{isServicesOpen ? '▲' : '▼'}
                            <i class="fa fa-caret-down"></i>*/}
                            
                            {/*<div class="dropdown-content">*/}
                                <ul class="dropdown-content">
                                    <li><Link style={{textDecoration: 'none', color:"black"}} to="/fliesenarbeiten">Fliesenarbeiten</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/fensterundtueren">Fenster und Türen</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/renovierung">Renovierung</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/sanitaetundheizung">Sanität und Heizung</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/tapezierenundstreichen">Tapezieren und Streichen</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/trockenbau">Trockenbau</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/wasserschaeden">Wasserschäden</Link></li>
                                    <li><Link style={{textDecoration: 'none'}} to="/zaunanlage">Zaunanlage</Link></li>
                                </ul></a>
                            {/*</div>*/}
                        </div>
                    </li>
                    <li className="nav-item" onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/projects">Galerie</Link></a></li>
                    <li className="nav-item" onClick={handleItemClick}><a className="link-a"><Link style={{textDecoration: 'none'}} to="/contactUs">Kontakt</Link></a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;