import React from "react";
import './Fensterundtueren.css';

const Fensterundtueren = () => {
    return (
        <section className="fensterundtueren">
            <div className="intro-fenster">
                <h1 className="titel">Fenster und Tür</h1>
                <div className="intro-fenster-text">
                    <h2>Einfach in der Handhabung und dauerhaft sicher</h2>
                    <p>Im Laufe der Zeit haben sich die Ansprüche an Fenster erheblich weiterentwickelt. </p>
                    <p>Während früher der Fokus hauptsächlich auf Lichtdurchlass und Wetterschutz lag, spielen heute zusätzliche Faktoren eine wichtige Rolle. </p>
                    <p>Moderne Fenster müssen nicht nur Wärmeschutz und Sicherheit bieten, sondern sich auch individuell anpassen und nahtlos in die Fassadenarchitektur integrieren lassen. 
                    Fortschritte in der Fertigungstechnologie, besonders bei Dachflächenfenstern, haben innovative bautechnische Lösungen ermöglicht. </p>
                    <p>Zudem sollte ein Fenster langlebig sein und idealerweise all Ihre Anforderungen erfüllen. </p>
                    <p>Angesichts der Vielzahl an Optionen gibt es kein „Standardfenster“ mehr, 
                    sondern stets eine Lösung, die genau zu Ihnen passt. Lassen Sie sich von uns beraten, um gemeinsam die optimale Lösung für Ihre Bedürfnisse zu finden.</p>
                </div>
                <img className="" id="renovierung-img" src= { require("../assets/images/fenster-installation.png")} alt="Ein Raum, bevore und nach Renovierung" width={'500px'} height={'100%'}></img>
            </div>
            <div className="intro-tueren">
                <div>
                    <h2>Bei uns haben Sie die Tür fest im Griff</h2>
                    <p>Die Haustür hat eine besondere Bedeutung und erzählt eine Geschichte. </p>
                    <p>Früher spiegelte sie oft den Status und den Geschmack des Hausbesitzers wider, was sich in aufwendigen Verzierungen und Details zeigte. 
                    Auch heute noch beeindrucken alte Haustüren durch ihre kunstvolle Gestaltung. </p>
                    <p>In der heutigen Zeit sind jedoch zusätzliche Aspekte in den Vordergrund gerückt: Wärmeschutz und Sicherheit sind zu zentralen Anforderungen geworden. 
                    Die Möglichkeiten zur Individualisierung sind dank moderner Materialien nahezu unbegrenzt. </p>
                    <p>Eine fachkundige Beratung ist daher der erste Schritt zu einer Haustür, die genau Ihren Vorstellungen entspricht. 
                    Kontaktieren Sie uns unverbindlich – wir unterstützen Sie gerne bei der Auswahl Ihrer perfekten Haustür.</p>
                </div>
                <img id="renovierung-img" src= { require("../assets/images/tueren-installation.png")} alt="Ein Raum, bevore und nach Renovierung" width={'500px'} height={'100%'}></img>
            </div>
            <div className="fensterundtueren-einbau">
                <h2>Tür-und Fenstereinbau</h2>
                <p>In einem Raum sind Tür und Fenster essenzielle Elemente. 
                Um Ihnen ein umfassendes Innenausbau-Komplettpaket anbieten zu können, gehören auch der Einbau von Türen und Fenstern zu unseren Fachleistungen. </p>
                <p>Wir montieren Tür- und Fensterzargen im Innenbereich und setzen anschließend die gewünschten Türen oder Fenster präzise ein. </p>
                <p>Beim Türeinbau bieten wir neben klassischen Scharniertüren auch Fenstertüren, Faltschiebetüren und normale Schiebetüren an. 
                So sorgen wir dafür, dass Ihr Raum nicht nur funktional, sondern auch optisch überzeugt.</p>
            </div>
        </section>
    );
};

export default Fensterundtueren;