import React from 'react';
import './Impressum.css';

const Impressum = () => {
    return (
      <div className="impressum">
        <h1>Impressum</h1>
        <p><strong>AMM Bau</strong></p>
        <p>Westkotterstr. 102<br />42277 Wuppertal</p>
  
        <h2>Vertreten durch:</h2>
        <p>Ahmad Almustafa</p>
  
        <h2>Kontakt:</h2>
        <p>Telefon: +49 176 31568708<br />E-Mail: info@amm-bau.de</p>
  
        {/*<h2>Registereintrag:</h2>
        <p>Eintragung im Handelsregister.<br />Registergericht: Wuppertal<br />Registernummer: HRB 36167</p>
  
        <h2>Umsatzsteuer-ID:</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE123456789</p>*/}
         
      </div>
    );
  }
  
  export default Impressum;