import React from 'react';
import './Abriss.css'; 

const Abriss = () => {
  return (
    <section className="abriss-section">
      <div className="container">
        <h2>Abrissarbeiten</h2>
        <p>
          Unser Bauunternehmen bietet professionelle Abrissarbeiten an, die sicher, effizient und umweltfreundlich
          durchgeführt werden. Egal ob Sie ein kleines Gebäude abreißen oder einen groß angelegten Abriss benötigen,
          wir verfügen über die notwendigen Ressourcen und das Fachwissen, um jede Aufgabe zu bewältigen. 
        </p>
        <p>
          Wir legen großen Wert auf die Sicherheit am Arbeitsplatz und arbeiten eng mit den zuständigen Behörden zusammen,
          um sicherzustellen, dass alle gesetzlichen Vorschriften eingehalten werden. Unsere modernen Maschinen ermöglichen
          es uns, Abrissarbeiten termingerecht und kostengünstig durchzuführen.
        </p>
        <img 
          src = {require("../assets/images/abrissarbeiten.png")}
          alt="Abrissarbeiten" 
          className="abriss-image"
        />
      </div>
    </section>
  );
};

export default Abriss;