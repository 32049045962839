import React from 'react';
import './Tankschutz.css'; 

const Tankschutz = () => {
  return (
    <section className="tankschutz-section">
      <div className="container">
        <h2>Tankschutz und Wartung</h2>
        <p>
          Der Schutz und die Wartung von Tanks sind essenziell, um die Langlebigkeit und die Sicherheit Ihres Tanksystems
          zu gewährleisten. Unser Unternehmen bietet umfassende Lösungen im Bereich des Tankschutzes, einschließlich der
          regelmäßigen Wartung, Reinigung und Instandsetzung von Tanks. 
        </p>
        <p>
          Mit unseren speziell geschulten Fachleuten und modernster Technik schützen wir Ihre Tanks vor Korrosion und
          anderen schädlichen Einflüssen. Dies erhöht nicht nur die Lebensdauer des Tanks, sondern trägt auch zum Schutz der
          Umwelt bei. 
        </p>
        <img 
          src= {require("../assets/images/tankschutz.png")}
          alt="Tankschutz" 
          className="tankschutz-image"
        />
      </div>
    </section>
  );
};

export default Tankschutz;